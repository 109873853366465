.boxContainer {
  &:first-child {
    margin: 24px auto;
  }
  h5 {
    color: rgb(237, 139, 0);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.35rem;
    font-size: 1.5rem;
    text-align: center;
  }
  p {
    line-height: 1.05;
    color: grey;
    font-family: 'Lato';
    text-align: justify;
    font-size: 1rem;
    font-weight: 400;
  }
  margin-bottom: 24px;
  background-color: white;
  padding: 24px;
}
